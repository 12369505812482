@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@500;700;900&family=Pontano+Sans&family=Roboto+Slab&family=Roboto:wght@400;500;700&display=swap');

html, body {
    padding: 0 !important;
    margin: 0 !important;
    font-family: Lato;

}

html {
    position: relative;
    min-height: 100%;
    padding-bottom: 160px;
}

body {
    background-color: #fafafa;
    font-weight: 400;
    text-align: inherit;
    font-size: 16px;
    line-height: 26px;
    word-spacing: 0px;
    letter-spacing: 0px;
    margin: 0;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* typography */
h1, h2, h3, h4, h5, h6 {
    font-family: Montserrat;
    font-weight: 700;
    text-align: inherit;
}


.header-title h1 {
    color: #000;
    font-family: Roboto;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
}

.title-underline {
    border-style: solid;
    color: #0060a6;
    border-width: 2px;
    margin-bottom: 30px;
    width: 100%;
}


@media (min-width: 650px) {
    h1 {
        width: auto;
        margin: 10px 10% 50px;
        text-align: left;
    }
    .header-title h1 {
        color: #000;
        font-family: Roboto;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
    }

    .title-underline {
        border-style: solid;
        color: #0060a6;
        border-width: 2px;
        margin-bottom: 30px;
        width: 300px;
    }
}

.project-title {
    margin: 0;
    margin-top: 20px;
}

.project-title  h1 {
    font-size: 36px;
    text-align: inherit;
    line-height: 45px;
    word-spacing: 0px;
    letter-spacing: 0px;
    font-weight: 700;
}

.project-title  h1.clearfix {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.project-title  span {
    display: block;
    position: relative;
    padding-bottom: 20px;
}

.project-title  span:after {
    position: absolute;
    content: '';
    background-color: #0060a6;
    height: 5px;
    width: 30%;
    max-width: 100%;
    bottom: 0;
    left: 0px;
}

#root a {
    cursor: pointer;
    outline: none;
    color: #161616;
    text-decoration: none !important;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#root a:hover, a:active, a:focus {
    color: #666666;
    text-decoration: none;
    outline: none;
}

/* end typography */


#footer {
    background-color: #000;
    bottom: 0;
    height: 100px;
    position: absolute;
    width: 100%;
    border-top: 1px solid #cacaca;
    padding: 0 30px;
}

#page-index #footer {
    margin-bottom: -100px;
}

#footer a {
    color: #fff;
    font-size: 30px;
}

#footer .icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    float: right;
    margin: 23px 5px;
}

#footer .icon-circle.vimeo {
    background-color: #19B7EA;
}

#footer .icon-circle.linkedin {
    background-color: #0072b1;
}

#footer .icon-circle.twitter {
    background-color: #1DA1F2;
}

#footer .icon-circle a, #footer .icon-circle a:hover {
    margin-left: 12px;
    margin-top: 3px;
    position: absolute;
    color: #fff;
}

#footer .icon-circle:hover {
    filter: brightness(115%) contrast(115%);
    transition: all .3s ease-in-out;
}

.navbar {
    background: transparent;
    z-index: 9999;
    height: 80px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
}

#navbarNav {
    float: right;
}

.navbar a.nav-link {
    font-family: Montserrat, Georgia, serif;
    margin-left: 15px;
    color: #063a7d;
    transition: all .4s;
}

.navbar a:hover {
    text-decoration: underline;
}

.navbar-collapse.collapsing, .navbar-collapse.collapse.show .ms-auto {
    margin-left: auto !important;
    z-index: 9999;
    background-color: rgb(255 255 255 / 98%);;
    color: #161616;
    border-radius: 5px;
}

.navbar .navbar-collapse.collapsing a.nav-link, .navbar .navbar-collapse.collapse.show a.nav-link {
    color: #084298!important;
}

.navbar .navbar-collapse.collapsing a.nav-link:hover, .navbar .navbar-collapse.collapse.show a.nav-link:hover {
    color: #000!important;
}

.navbar .navbar-nav .nav-link {
    color: #000000;
    font-size: 1.1em;
}
.navbar .navbar-nav .nav-link:hover {
    color: #000000;
}
.navbar .navbar-nav .nav-link {
    position: relative;
}

.navbar .navbar-nav .nav-link:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #5bcbf5;
    width: 0%;
    content: "";
    height: 4px;
    transition: all 0.5s;
}

.navbar .navbar-nav .nav-link:hover::after {
    width: 100%;
}

.navbar-collapse.collapsing .nav-link:after , .navbar-collapse.collapse.show .nav-link:after {
    background-color: #fff;
}

/* homepage */
#page-index section {
    padding: 30px 0;
    overflow: auto;
}

#page-index .navbar a {
    color: #5bcbf5;
}

#page-index .navbar a:hover {
    color: #5bcbf5;
    color: #063a7d;
}

#demoreel {
    background-image: url("../public/images/VIS_BACKGROUND-4.jpeg");
    height: 50vh;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin-top: -120px;
    background-size: cover;
    background-position: center;
}

#banner-overlay {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 990;
    max-width: 100%;
    overflow: hidden;
}

.banner-logo {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
}

.banner-logo img {
    z-index: 991;
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-top: 35%;
}

@media (min-width: 780px) {
    #demoreel {
        height: 90vh;
    }

    .banner-logo img {
        margin: 0 auto;
        margin-top: 15%;
        margin-bottom: 15%;
    }
}

.projects-button {
    text-align: center;
    margin: 0 auto;
}

section {
    display: block;
    clear: both;
}
section#reference {
    margin-top: 30px;
    padding-bottom: 0;
}

section#reference .container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 10px;
}

section#reference img {
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: none;
}

section#reference .quotation {
    color: #7A7A7A;
    font-weight: 400;
    font-family: "Pontano Sans", Sans-serif;
    font-size: 20px;
    text-align: center;
    line-height: 1.3em;
    margin-bottom: 20px;
}

section#reference .person {
    width: 100%;
    text-align: center;
}

section#reference .photo {
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

section#reference .name {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #0060a6;
    font-size: 20px;
    margin-bottom: -5px;
}

section#reference .title {
    font-size: 20px;
    color: #54595F;
}

@media (min-width: 550px) {
    section#reference .photo {
        float: left;
        margin-left: 20px;
    }

    section#reference .person {
        width: 300px;
        display: inline-block;
        float: left;
        margin: 25px 15px 10px;
        text-align: left;
    }

    section#reference .name {
    }
}

section#clients img {
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 0 auto;
    margin: 30px auto;
}

@media (min-width: 767px) {
    section#clients .col img {
        max-width: 200px;
        margin: 10px auto;
        max-width: 80%
    }
}

#page-index section#services {
    padding: 0;
    background-color: #0060a6;
    background-image: url("../public/images/pexels-photo3.jpg");
    background-position: center 75%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 18px;
}

#services-overlay {
    background-color: #0060a6;
    opacity: 0.69;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
}

#page-index section#services .container {
    z-index: 999;
    position: relative;
    color: #fff;
}

section#services .image-wrapper {
    width: 171px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border-style: solid;
    border-color: #5bcbf5;
}

section#services .image-wrapper img {
    width: 100%;
}

section#services .name {
    color: #ffffff;
    font-size: 21px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: -5px;
}

section#services .title {
    color: #f7f7f7;
    font-size: 17px;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    margin-bottom: 30px;
}

section#services .start-now {
    padding: 50px 0 70px;
    display: block;
}

section#services .start-now a {
    color: #ffffff;
    font-size: 45px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    transition: all 0.5s ease;
}

section#services .start-now a:hover {
    color: #000;
}

section#services h3 {
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 80px 0 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}

section#services .divider {
    border-bottom: 1px solid #fff;
}

section#services ul#services-list {
    font-size: 1em;
    text-align: left;
    padding: 0;
}

section#services ul#services-list li {
    list-style-type: none;
    margin-bottom: 20px;
}

section#services ul#services-list li svg {
    margin-top: 11px;
    margin-right: 20px;
    height: 25px;
    display: none;
}

section#services ul#services-list li .service {
    font-size: 32px;
    font-family: Roboto;
    font-weight: 600;
    transition: all 0.5s ease;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
}

@media (min-width: 767px) {
    section#services ul#services-list li .service {
        text-align: left;
    }
    section#services ul#services-list li svg {
        display: block;
        float: left;
    }
}

section#services ul#services-list li .service-description {
    display: block;
    font-size: 1.2em;
    margin: 0 auto;
    width: 80%;
    font-size: 1em;
}

iframe#demo-reel {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    width: 177.77777778vh;
    display: none;
}

iframe#demo-reel .vp-controls-wrapper {
    display: none;
}

@media (min-width: 767px) {
    iframe#demo-reel {
        display: block;
    }
}

/* end homepage */

#pagebody {
    max-width: 1600px;
    margin: 0 auto;
}

.project-masonry {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}

.project-masonry_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}

.project-masonry_column > div {
    margin-bottom: 10px;
}

.project-item {
    max-width: 585px;
    position: relative;
}

.project-item img {
    max-width: 100%;
    transition: all 0.5s ease;
}

.project-record {
    margin-top: 50px;
    margin-bottom: 100px;
}

.project-record .project-meta {
    margin-top: 30px;
    font-weight: 700;

}

.project-record .project-meta span {
    font-weight: 700;
    font-family: Lato;
    letter-spacing: 1px;
}

.project-description {
    margin: 30px 10px;
    color: #555;
}

ul.project-filter-menu {
    margin: 20px 0 30px 0;
    text-align: center;
    display: none;
}

@media (min-width: 650px) {
    ul.project-filter-menu {
        display: block;
    }
}

.project-filter-menu li {
    font-size: .8em;
    display: inline;
    margin: 0 30px;
    padding: 10px;
}

.project-filter-menu li.active {
    border: 1px solid #000;
}

.project-item figcaption {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    padding: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    margin: 0 auto;
}

.project-back {
    position: relative;
    margin: 30px 15px;
}

.project-back a {
    padding-left: 40px;
    font-size: 22px;
}

.project-back:after {
    position: absolute;
    content: '';
    background-color: #0060a6;;
    height: 3px;
    width: 30px;
    top: 55%;
    transform: translateY(-55%);
    left: 0;
}

.projects-logo img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
    top: 15px;
    padding-bottom: 35px;
}

section#upwork {
    background-color: #0060a6;
    padding: 10%;
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 34px;
    font-weight: 900;
    text-align: center;
    bottom: 0;
    width: 100%;
    min-height: 400px;
}

section#upwork .intro {
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: inherit;
    line-height: 45px;
    word-spacing: 0;
    letter-spacing: 0;
}

section#upwork a, section#upwork a:hover {
    color: #ffffff;

}

section#upwork a.lets-work {
    color: #000;
    border-bottom: 3px solid #000;
}

@media (min-width: 600px) {
    section#upwork {
        font-size: 45px;
        padding: 80px;
    }
}

figcaption .caption-inside {
    text-align: center;
    margin-top: 16%;
}

#project-grid {
    width: 100%;
    display: block;
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
}

#page-contact #pagebody {
    padding-top: 10%;
    max-width: 800px;
}

#page-contact #recaptcha div:nth-child(1) {
    margin: 0 auto;
    display:block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.project-embed iframe {
    max-width: 100%;
}

[class^='imghvr-'], [class*=' imghvr-'], [class^='imghvr-']:before, [class^='imghvr-']:after, [class*=' imghvr-']:before, [class*=' imghvr-']:after, [class^='imghvr-'] *, [class*=' imghvr-'] *, [class^='imghvr-'] *:before, [class^='imghvr-'] *:after, [class*=' imghvr-'] *:before, [class*=' imghvr-'] *:after {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

.imghvr-fade.imghvr-blur {
    background-color: rgba(0, 0, 0, 0.8);
}

.button-wrapper {
    width: 100%;
    height: 30px;
    display: block;
    position: relative;
    text-align: center;
}

.button-wrapper a.btn.btn-primary {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff !important;
    background-color: #60a600;
    border-radius: 0px;
    border: none;
    text-align: center;
    padding: 15px 30px;
    transition: all 0.8s ease;
}

a.btn.btn-primary:hover {
    margin-top: 10px;
    color: #000000!important;
    background-color: #5bcbf5;
}

.contact-form {
    margin-top: 30px;
}

.contact-form-wrapper {
    max-width: 800px;
    display: block;
    margin: 0 auto;
    padding: 0 50px;
    margin-bottom: 100px;
    font-family: Lato;
    font-weight: 400;
    text-align: inherit;
    font-size: 16px;
    line-height: 26px;
    word-spacing: 0px;
    letter-spacing: 0px;
}

.thanks {
    text-align: center;
    margin: 30px 0;
    padding-bottom: 50px;
    font-size: 1.2em;
}

input:focus, input.form-control:focus, textarea.form-control:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.contact-form input, .contact-form textarea {
    height: 40px;
    width: 300px;
    padding: 0 15px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background-color: #EFEFEF;
    border-color: #EFEFEF;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 0;
    margin-bottom: 20px;
}

.contact-form textarea {
    height: 300px;
    width: 100%;
    padding-bottom: 10px;
    display: block;
}

.contact-form input[type="submit"] {
    display: inline-block;
    color: #fff;
    background-color: #ff0036;
    font-size: 16px;
    letter-spacing: 0.025em;
    width: auto;
    border: none;
    margin-top: 0;
    cursor: pointer;
}

.contact-form input[type="submit"]:disabled {
    background-color: #bcbcbc;
    color: #6f6f6f;
}

#page-roadmap .navbar {
    display: none;
}

#page-roadmap #footer {
    display: none;
}